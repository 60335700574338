@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}



/* index.css */
body {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  background-color: #121212;
}



/* Music notes styles */
.music-notes {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none; /* Prevent interference with UI elements */
  z-index: -1; /* Place behind the UI */
}

.music-notes .note {
  position: absolute;
  font-size: 40px;
  color: rgba(255, 255, 255, 0.6); /* Soft white color for the notes */
  text-shadow: 0 0 10px #9b4dff, 0 0 20px #9b4dff, 0 0 30px #9b4dff; /* Glowing purple effect */
  opacity: 0.8;
  animation: float 7s infinite linear;
}

/* Keyframes for floating animation */
@keyframes float {
  0% {
    transform: translateY(100vh) rotate(0deg);
    opacity: 0.8;
  }
  50% {
    opacity: 1;
    transform: translateY(-50vh) rotate(180deg);
  }
  100% {
    transform: translateY(-100vh) rotate(360deg);
    opacity: 0.8;
  }
}

/* Adjust positions and animation for each note */
.music-notes .note:nth-child(2) {
  left: 20%;
  animation-duration: 8s;
  animation-delay: 2s;
}

.music-notes .note:nth-child(3) {
  left: 40%;
  animation-duration: 9s;
  animation-delay: 1s;
}

.music-notes .note:nth-child(4) {
  left: 60%;
  animation-duration: 7s;
  animation-delay: 3s;
}

.music-notes .note:nth-child(5) {
  left: 80%;
  animation-duration: 10s;
  animation-delay: 4s;
}
